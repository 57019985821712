import type { FunctionComponent } from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

import Footer from '@/components/Footer'
import Header from '@/components/Header'
import SearchInputByAddress from '@/components/SearchInputByAddress'
import { SearchInputByAddressVariant } from '@/components/SearchInputByAddress/AddressAutocompleteComponent'
import { buildAssetsPath } from '@/libs/helpers/assets'
import { HeaderVariant, OrderMethod } from '@/libs/helpers/utils'
import { Grid, Text } from '@/tbui'

const StyledPageBody = styled(Grid)`
  background-image: url(${buildAssetsPath('/images/home-bg.jpg')});
  background-position: 65% 50%;
  grid-template-rows: auto 1fr;
  background-size: cover;
  height: 100vh;
`

const StyledText = styled(Text)`
  ${(props) =>
    props.theme.mixin.fluidRange({
      prop: 'fontSize',
      fromSize: '18px',
      toSize: '36px',
    })};
  @media (max-width: ${(props) => props.theme.breakpoints.SM}) {
    max-width: 55vw;
  }
`

const HomePage: FunctionComponent<{ orderMethod: OrderMethod }> = ({ orderMethod }) => {
  const { t } = useTranslation()

  return (
    <>
      <StyledPageBody align="center">
        <Header
          variant={HeaderVariant.HOME}
          cart={{ isCartLoading: false, orderMethod }}
          venueXRefID={undefined}
        />
        <Grid container p="24px" align="center" gap="20px">
          <StyledText>{t('tbdine.title_home')}</StyledText>
          <SearchInputByAddress
            variant={SearchInputByAddressVariant.ADDRESS_HOME}
            orderMethod={orderMethod}
          />
        </Grid>
      </StyledPageBody>
      <Footer />
    </>
  )
}

export default HomePage
